import { Component } from "../extends";

export default () =>
	class Hero extends Component() {
		events() {
			this.resizeObserver = new ResizeObserver(() => this.onResize());

			this.resizeObserver.observe(this.el);
		}

		mounted() {
			this.title = this.el.querySelector(".c-hero__title");
			this.titleHeight = 0;
			this.content = this.el.querySelector(".c-hero__content");
			this.inner = this.el.querySelector(".c-hero__inner");

			this.getHeight();
			this.setHeight();
		}

		setHeight() {
			if (window.innerWidth <= 720) {
				this.content.style.transform = "translateY(0)";
				this.inner.style.marginBottom = "0";
			} else {
				const translateValue = this.titleHeight - 60;
				if (translateValue <= 48) {
					this.content.style.transform = "translateY(48px)";
				} else {
					this.content.style.transform = `translateY(${translateValue})`;
				}
				this.inner.style.marginBottom = `calc(${this.titleHeight}px - 20px)`;
			}
		}

		getHeight() {
			this.titleHeight = this.title.getBoundingClientRect().height;
		}

		onResize() {
			this.getHeight();
			this.setHeight();
		}
	};
