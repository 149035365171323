import components from "./components";
import { init as initComponent } from "./utils/dom";

// Initialise & register all JavaScript components
const init = () => {
	initComponent(".js-animated-icons", components.AnimatedIcons);
	initComponent(".js-info", components.Info);
	initComponent(".js-hamburger", components.Hamburger);
	initComponent(".js-header", components.Header);
	initComponent(".js-mobile-nav", components.MobileNav);
	initComponent(".js-mobile-nav", components.MobileNav);
	initComponent(".js-nav-submenu", components.NavSubmenu);
	initComponent(".js-hero", components.Hero);
	initComponent("#body", components.Body);
};

/**
 * Check if the document is ready to initialise the JavaScript components.
 * Interactive indicates: execute when document has finished loading, but
 * images, stylesheets and frames are still loading.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
 */
document.onreadystatechange = () => {
	if (document.readyState === "interactive") {
		init();
	}
};
