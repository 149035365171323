import { Component } from "../extends";

export default () =>
	class MobileNav extends Component() {
		events() {
			this.$EE.on("Hamburger:enable", () => this.open());
			this.$EE.on("Hamburger:disable", () => this.close());
		}

		mounted() {
			this.focusEls = [...this.el.querySelectorAll(".c-nav__link")];
		}

		open() {
			this.el.classList.add("is-open");
		}

		close() {
			this.el.classList.remove("is-open");
		}

		onKeydown(e) {
			if (!this.$Store.isMobileNavOpen) {
				return;
			}

			const isTab = e.key === "Tab" || e.keyCode === 9;
			const isShift = e.shiftKey;

			if (!isTab) {
				return;
			}

			const focusedEl = document.activeElement;
			const firstEl = this.focusEls[0];
			const lastEl = this.focusEls[this.focusEls.length - 1];

			if (focusedEl === firstEl && isShift) {
				this.$EE.emit("MobileMenu:focus-first");
				e.preventDefault();
			}

			if (focusedEl === lastEl && !isShift) {
				e.preventDefault();
			}
		}
	};
