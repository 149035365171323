import { EventEmitter, Store, Keyboard } from "../singletons";

export default () =>
	class Component {
		constructor(el, ...params) {
			this.el = el;
			this.$EE = EventEmitter;
			this.$Store = Store;

			if (this.mounted) {
				this.mounted(...params);
			}

			if (this.events) {
				this.events();
			}

			if (this.onKeydown) {
				Keyboard.add((e) => this.onKeydown(e));
			}
		}
	};
