import { Component } from "../extends";

export default () =>
	class SiteHeader extends Component() {
		events() {
			this.$EE.on("MobileMenu:focus-last", () => this.focusHamburger());
			this.$EE.on("MobileMenu:focus-first", () => this.focusLogo());

			window.addEventListener("scroll", () => this.handleScroll());
		}

		mounted() {
			this.logo = this.el.querySelector(".c-header__logo");
			this.hamburger = this.el.querySelector(".c-header__hamburger");
		}

		onKeydown(e) {
			if (!this.$Store.isMobileNavOpen) {
				return;
			}

			const isShift = e.shiftKey;
			const focusedEl = document.activeElement;

			if (this.hamburger === focusedEl && isShift) {
				this.$EE.emit("SiteHeader:focus-out-hambuger");
				e.preventDefault();
			}
		}

		focusLogo() {
			this.logo.focus();
		}

		focusHamburger() {
			this.hamburger.focus();
		}

		handleScroll() {
			if (document.documentElement.scrollTop > 500) {
				this.el.classList.add("s-inverted");
			} else {
				this.el.classList.remove("s-inverted");
			}
		}
	};
