import { EventEmitter, Store } from "../singletons";

export const init = (selector, Module = null, params = {}) => {
	const els = document.querySelectorAll(selector) || [];
	const ctx = {
		EventEmitter,
		Store,
	};

	const instances = Module
		? [...els].map((el) => new (Module(ctx))(el, params))
		: [...els];

	if (instances.length === 1) {
		return instances[0] || null;
	}

	return instances || [];
};
