import { Component } from "../../extends";

export default (ctx) =>
	class AnimatedIcon extends Component(ctx) {
		events() {
			this.el.addEventListener("complete", () => this.onComplete());
		}

		mounted({ index, endedCb }) {
			this.endedCb = endedCb;
			this.index = index;
			this.isReversed = false;
		}

		play() {
			this.el.setDirection(1);
			this.el.play();
		}

		onComplete() {
			if (!this.isReversed) {
				this.reverse();
			} else {
				this.completed();
			}
		}

		reverse() {
			this.el.setDirection(-1);
			this.el.play();
			this.isReversed = true;
		}

		completed() {
			if (this.endedCb) {
				this.endedCb();
			}

			this.isReversed = false;
		}

		stop() {
			this.el.stop();
		}
	};
