import { Component } from "../../extends";
import AnimatedIcon from "./AnimatedIcon";

export default (ctx) =>
	class Info extends Component(ctx) {
		events() {
			this.el.addEventListener("mouseover", () => this.mouseover());
			this.el.addEventListener("mouseleave", () => this.mouseleave());
		}

		mounted() {
			this.animatedIcon = new (AnimatedIcon(ctx))(
				this.el.querySelector(".c-animated-icon")
			);
		}

		mouseover() {
			this.animatedIcon.play();
		}

		mouseleave() {
			this.animatedIcon.reverse();
		}
	};
