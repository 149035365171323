import { Component } from "../../extends";
import AnimatedIcon from "./AnimatedIcon";

export default (ctx) =>
	class AnimatedIcons extends Component(ctx) {
		mounted() {
			this.animatedIcons = this.initIcons();

			const options = {
				threshold: 0.3,
			};

			this.observer = new IntersectionObserver(
				(...e) => this.handleIntersection(...e),
				options
			);

			this.observer.observe(this.el);
		}

		initIcons() {
			const els = this.el.querySelectorAll(".js-animated-icon");
			const result = [];

			for (let i = 0; i < els.length; i += 1) {
				result.push(
					new (AnimatedIcon(ctx))(els[i], {
						index: i,
						endedCb: (...e) => this.handleNext(i, ...e),
					})
				);
			}

			return result;
		}

		handleNext(index) {
			if (index === this.animatedIcons.length - 1) {
				this.animatedIcons[0].play();
			} else {
				this.animatedIcons[index + 1].play();
			}
		}

		handleIntersection(entries) {
			const entry = entries[0];

			if (entry.isIntersecting) {
				this.animatedIcons[0].play();
			} else {
				for (let i = 0; i < this.animatedIcons.length; i += 1) {
					this.animatedIcons[i].stop();
				}
			}
		}
	};
