import { Component } from "../extends";

export default () =>
	class NavSubmenu extends Component() {
		mounted() {
			this.header = this.el.querySelector(".c-nav-submenu__header");
			this.toggleClass = "is-open";
		}

		events() {
			this.header.addEventListener("click", () => this.toggle());

			window.addEventListener("click", (e) =>
				this.closeOnOutsideClick(e)
			);
		}

		toggle() {
			if (this.el.classList.contains(this.toggleClass)) {
				this.close();
			} else {
				this.open();
			}
		}

		open() {
			this.el.classList.add(this.toggleClass);

			this.header.setAttribute("aria-label", "Close item");
			this.header.setAttribute("aria-expanded", "false");
		}

		close() {
			this.el.classList.remove(this.toggleClass);

			this.header.setAttribute("aria-label", "Open item");
			this.header.setAttribute("aria-expanded", "true");
		}

		/**
		 * This functions closes the subnav when clicked outside. This behavior
		 * should only happen when the subnav is in desktop-mode
		 */
		closeOnOutsideClick(e) {
			if (!this.el.classList.contains(this.toggleClass)) {
				return;
			}

			const clickableAtViewport = 992;

			const { offsetWidth } = document.body;

			if (clickableAtViewport > offsetWidth) {
				return;
			}

			if (!this.el.contains(e.target)) {
				this.close();
			}
		}
	};
