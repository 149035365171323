import { Component } from "../../extends";

export default (ctx) =>
	class AnimatedIcon extends Component(ctx) {
		play() {
			this.el.setDirection(1);
			this.el.play();
		}

		reverse() {
			this.el.setDirection(-1);
			this.el.play();
			this.isReversed = true;
		}
	};
